.formContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 85%;
  max-width: 800px;
}
.dropdown {
  display: flex;
  flex-direction: column;
}

.vaccinepage {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #F2F2F2;
  width: calc(100% + 30px);
  margin: 0 -15px;
  padding-top: 20px;
  flex-grow: 1;
}
.errormsg {
  color:red;
  margin-bottom: 0;
}

.hidden {
  visibility: hidden;
}

.no-display {
  display: none;
}