.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
}
.icon {
  font-size: 24px;
  min-width: 40px;
  min-height: 40px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup {
  transition: opacity 0.5s ease 0s, flex 0.3s ease-out 0s;
  place-items: center;
  -webkit-box-align: center;
  display: flex;
  height: 48px;
  padding: 4px;
  border-radius: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 42px;
  cursor: default;
}
.filled {
  color: #209AE5;
}
.bold {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1875rem;
}
.likecontainer {
  height: 48px;
}