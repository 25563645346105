.slide-up-down-fast-enter {
  /* transform: translateY(100%); */
  opacity: 0;
}
.slide-up-down-fast-enter-active {
  /* transform: translateY(0%); */
  opacity: 1;
  transition: all 200ms ease-out;
}
.slide-up-down-fast-exit {
  opacity: 1;
  /* transform: translateY(0%); */
}
.slide-up-down-fast-exit-active {
  opacity: 0;
  /* transform: translateY(100%); */
  transition: all 200ms ease-out;
}