.labelContainer{
  display: flex;
  flex-direction: column;
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.label {
  font-size: 40;
  font-weight: 800;
  /* align-self: center; */
}
.card {
  background-color: #e8e8e8;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px 15px;
  border-radius: 6px;
}
.cardTitle {
  font-weight: 800;
}
.note {
  font-weight: 500;
  font-style: italic;
}