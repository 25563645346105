.drawer{
    bottom: 0px !important;
}

.drawer > div{
    height: fit-content !important;
    bottom: 10px;
  
}
.drawer > div > div > div{
    height: fit-content !important;
    bottom: 10px;
  
}