.slide-up-down-enter {
  /* opacity: 0; */
  /* top: 100%; */
  transform: translateY(100%);
}
.slide-up-down-enter-active {
  transform: translateY(0%);
  transition: transform 300ms ease-out;
}
.slide-up-down-exit {
  transform: translateY(0%);
}
.slide-up-down-exit-active {
  transform: translateY(100%);
  transition: transform 300ms ease-out;
}