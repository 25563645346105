.flex {
  display: flex;
  
}
.flexcolumn {
  flex-direction: column;
}
.flexcenter {
  justify-content: center;
  align-items: center;
}
.fullscreen {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 8888;
}
.primary {
  background-color: #209AE5;
}
.maintext {
  align-self: center;
  flex-grow: 1;
  font-weight: 1000;
  color: white;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.maintext > div {
  width: 100%;
}
.divider{
  color: rgba(255,255,255,0.60)!important;
  border-top-color: rgba(255,255,255,0.5)!important;
}
.error {
  color: #ff4d4f!important;
}
.continuebutton {
  display: flex;
  margin: 12px auto;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
}
.secondarytext {
  font-size: 17px;
  text-align: center;
  line-height: 1.2em;
  margin-bottom: 24px;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
  max-width: 450px;
}
.maininput {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.247);
  height: 46px;
  width: 100%;
  border-radius: 6px;
  padding: 0px 12px;
  font-size: 18px;
  color: white;
  outline: none!important;
  font-weight: 500;
}
.maininput::placeholder {
  color:rgba(255, 255, 255, 0.88);
}
.closebutton {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  color: white;
  z-index: 9999;
}