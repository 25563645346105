.container-main {
  position: relative;
  overflow-x: clip;
}

.page {
  position: absolute;
  width: 100%;
  padding-bottom: 47px;
  /* will-change: transform; */
}

.page-enter.left {
  transform: translateX(100%);

}

.page-enter-active.left {
  transform: translateX(0%);
  transition: transform 300ms ease-out;
}

.page-exit.left {
  transform: translateX(0%);
  /* transform: scale(1); */
}

.page-exit-active.left {
  /* opacity: 0; */
  /* transform: scale(0.9); */
  transform: translateX(-100%);
  transition: transform 300ms ease-out;
}

.page-enter.right {
  transform: translateX(-100%);

}

.page-enter-active.right {
  /* right: 0%; */
  transform: translateX(0%);
  transition: transform 300ms ease-out;
}

.page-exit.right {
  /* left: 0; */
  transform: translateX(0%);
}

.page-exit-active.right {
  transform: translateX(100%);
  transition: transform 300ms ease-out;
}
