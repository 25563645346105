.flex {
  display: flex;
}
.flex .box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box:first-child > * { margin-right: auto; }

.box:last-child  > * { margin-left: auto;  }
