.background input {
    background: #0066b2;
  }

  .text-white-imp {
    color: rgba(255,255,255,0.88)!important;
  }
  .text-white-imp input {
    color: rgba(255,255,255,0.88)!important;

  }