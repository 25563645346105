.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}
.relative {
  position: relative;
}
.header {
  flex-shrink: 0;
  margin-left: 0;
  margin-right: 0;
}
.back, .postbutton {
  flex-shrink: 0;
  color: white;
  font-weight: 500;
}
.title {
  flex-grow: 1;
  text-align: center;
}