body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-dropdown {
  border-radius: 6px;
}
.custom-dropdown .ant-select-selector {
  border-radius: 6px!important;
}
.ant-message {
  z-index: 9999 !important;
}

img {
  image-rendering: -moz-crisp-edges;         /* Firefox */
  image-rendering:   -o-crisp-edges;         /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
}
.survey-form {
  border: none;
  margin: 0 -15px!important;
  width: calc(100% + 30px)!important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .touched:active {
    background-color: #f2f2f2!important;
  }
  .touched {
    transition: background-color 250ms ease-out 0s;
  }
}
