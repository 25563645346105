.iframeContainer{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 -15px;
}
.iframe {
  border: none;
  align-self: center;
  flex-grow: 1;
}